.Appp {
  text-align: center;
  display:flex;
  height:100vh;
  align-items: center;
  justify-content: center;
  color: black;
  background-image:linear-gradient(#f18787 , white 50% ,#f18787  )
}

.App{
  text-align: center;
  overflow-y: auto;
  height:100vh;
  align-items: center;
  justify-content: center;
  color: black;
  background-image:linear-gradient(#f18787  , white 50% ,#f18787  )
}

.studentimg{
  margin-top: 0px;
}

.homme{
  height: 715px;
  
  text-align: center;
  
}
.aboutus{
  height: 715px;
  background-color: #a7e0ff;
  text-align: center;
  justify-content: center;
}

.contact{
  height: 715px;
  ;
  text-align: center;
  justify-content: center;
}

.noti{
  height: 715px;
  background-color: #a7e0ff;
  text-align: center;
  justify-content: center;
}
.others{
  height: 715px;
  
  text-align: center;
  justify-content: center;
}

.img{
  padding-bottom: 0px;
}

.p{
  margin-top: 0px;
  margin-bottom: 10px;
}

.nav-link active{
  padding-left: 30px;
  padding-right: 30px;
}

.stylishtext{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.marquee{
  background-color:#005daa;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  color:white;
}

.font{
  font-family: 'Gloock', serif;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.forpass{
  display: flex ;
  align-items: flex-end;
}

.headfont{
  font-family: 'Montserrat', sans-serif;
 
}

.mainimg{
  height: 597px;
  opacity: 0.5;
  width: 1px;
}
 
.col {
  height: 450px;
  width: 60px;
  border: 2px solid white;
  
}


.hr {
   width:100%;
  height:1px; 
  background: solid rgb(4, 4, 4);
  margin-bottom: 0px;
  
}

.try{
  margin-top: 8px;
}

#Admin{
  height: 10px;
  width: 10px;
}

#sLogin{
  margin-bottom: 20px;
  text-align: center;
}

.Llogin{
  position: relative;
        left: 0px;
}

.adminimg{
  height: 50px;
  width: 50px;
  float: left;
  margin-left: 20px;
  
}

.studentimg{
  height: 40px;
  width: 40px;
  float: left;
  
  margin-left: 20px;
  
}

.img{
  display:block;
    margin: auto;
    margin-top: 20px;
    width: 100px;
    height: 120px;
    padding-bottom: 15px;
}
.auth-form-container,.login-form{
  
  display: flex;
  flex-direction: column;
  
  
  
  
}
@media screen and (min-width :375px){
.auth-form-container{
  padding-left: 10%;

  padding-right: 10%;
  border: 3px solid rgb(7, 5, 5);
  border-radius: 10px;
  margin:0.5rem;
  background-color: rgb(232, 223, 223);
  
}
}



.try{
  text-align: left;
  padding-bottom: 10px;
  margin-top: 0px;
}

.tryinput{
  margin: 0.5rem 0;
  padding: 1rem;
  border:none;
  border-radius: 10px;
  border: 1px solid rgb(7, 5, 5);

}

.Llogin{
  border:none;
  background-color: aliceblue;
  padding:10px;
  margin-bottom: 40px;
  border-radius: 10px;
  cursor: pointer;
  color:black;
  border: 1px solid rgb(7, 5, 5);
}

.loginbutton{
  border:none;
  background-color: aliceblue;
  padding:10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;
  color:red;
  border: 1px solid rgb(7, 5, 5);
}

#password{
  margin-bottom: 0px;
}

#prn{
  margin-bottom: 0px;
}

.scrollbutton{
  position: fixed;
  bottom: 15px;
  right: 20px;

}

.topstyle{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #333;
  background-color: #fff;
  color:#333;
  cursor:pointer;
}

.span{
  font-family: 'Source Serif Pro', serif;
}

.topstyle :hover{
  animation: none;
  background-color: #333;
  color: #f18787;
  border: 2px solid #fff;
}

.d-block{
  height: 500px;
  width: 880px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}

.caimag{
  text-align: center;
}